@import global

.search-field
    width: 100%

.input_field_container
    background-color: white
    border: 1px solid #C1C2CF
    transition: border-color 0.3s
    border-radius: 20px
    display: flex

    &:focus-within
        outline: none
        border: 1px solid #3367B4
        box-shadow: 0 0 3px #3367B4

    &.small
        height: 30px

    &.medium
        height: 40px

    &.large
        height: 50px

    .error
        border-color: #dc3545

        &:focus
            border-color: #dc3545
            box-shadow: 0 0 5px rgba(220, 53, 69, 0.5)

    .input_field
        padding: 5px
        border: none
        font-size: 16px
        width: 100%
        font-family: 'Roboto','sans-serif'
        font-weight: 100

        &::placeholder
            font-family: 'Roboto','sans-serif'
            font-weight: 100

        &:focus
            outline: none

        &::placeholder
            color: #C1C2CF
            font-weight: normal

        &.small
            margin: 7px
            font-size: 12px
            padding: 4px 8px
            font-weight: lighter

        &.medium
            margin: 10px
            font-size: 16px
            padding: 6px 12px
            font-weight: 300

        &.large
            margin: 15px
            font-size: 20px
            padding: 8px 16px

        &:focus
            outline: none

        &::placeholder
            color: #C1C2CF
            font-weight: normal