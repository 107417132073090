.App
	text-align: center 
.App-logo 
	height: 40vmin 
	pointer-events: none 
.App-link 
	color: #61dafb 

.wrapper
	display: -webkit-flex
	display: flex
	-webkit-flex-direction: column
	flex-direction: column
	-webkit-flex-wrap: wrap
	flex-wrap: wrap
	-webkit-justify-content: space-between
	justify-content: space-between
	-webkit-align-content: stretch
	align-content: stretch
	-webkit-align-items: center
	align-items: center
	width: 100%
	height: 100%
	min-width: 600px

.fullwidth
	width: 100%