.horizontal-scroll-container
  display: flex
  flex-wrap: nowrap
  gap: var(--spacing)
  overflow-x: auto
  padding-bottom: 10px
  -webkit-overflow-scrolling: touch
  align-items: stretch

  .horizontal-scroll-item
    flex: 0 0 auto
    min-width: 200px
    display: flex
    flex-direction: column
    justify-content: stretch

// Медиа-запрос для мобильных устройств
@media (max-width: 600px)
  .horizontal-scroll-container .horizontal-scroll-item
    flex: 0 0 calc(50% + var(--spacing) / 2) // Устанавливаем ширину элемента так, чтобы было видно чуть больше двух элементов
    min-width: calc(50% + var(--spacing) / 2)


.horizontal-scroll-title
  display: flex
  flex-direction: column
  align-items: stretch
