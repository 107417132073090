.box
    display: -webkit-flex
    display: flex
    -webkit-flex-direction: column
    flex-direction: column
    -webkit-flex-wrap: wrap
    flex-wrap: wrap
    -webkit-justify-content: space-between
    justify-content: space-between
    -webkit-align-content: stretch
    align-content: stretch
    -webkit-align-items: center
    box-sizing: border-box
    