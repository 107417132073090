.product-card
  position: relative
  -webkit-align-items: flex-start
  width: 100%

.product-card-image
  border-radius: 0 0 20px 20px
  overflow: hidden
  width: 100%

.overlay-button
  position: absolute
  top: 80px
  left: 10%
  transform: translate(-50%, -50%)
  padding: 10px 20px
  font-size: 16px
  background-color: rgba(0, 0, 0, 0.0)
  color: white
  border: none
  cursor: pointer
  z-index: 1000
