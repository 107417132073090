.list
    list-style-type: none
    padding: 0
    margin: 0
    width: 100%

    .list-item
        display: flex
        align-items: center
        padding: 8px 16px

        &:last-child
            border-bottom: none
