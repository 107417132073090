.divider
  background-color: #A8A9B0

  &.horizontal
    height: 1px
    width: 100%
    margin: 8px 0

  &.vertical
    width: 1px
    height: 100%
    margin: 0 8px
