.left-body
  padding: 70px

.right-body
  padding-left: 70px

.image-right
  position: absolute
  width: 50vw
  height: 100vh
  object-fit: cover
  top: 0
  right: 0

.image-robot
  position: absolute
  width: 50vw
  object-fit: cover
  bottom: 0
  right: 0
  z-index: 1001