.loader-container
  display: flex
  justify-content: center
  align-items: center
  height: 100%  // Высота контейнера равна высоте окна

.loader
  border: 16px solid #f3f3f3  // Светло-серый цвет для внешнего кольца
  border-top: 16px solid #3498db  // Синий цвет для верхней части кольца
  border-radius: 50%
  width: 120px
  height: 120px
  animation: spin 2s linear infinite  // Анимация вращения

@keyframes spin
  0%
    transform: rotate(0deg)
  100%
    transform: rotate(360deg)
