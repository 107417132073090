@import global

$button_width: 147px
$button_height: 44px
$button_padding: 10px 20px 10px 20px
$border_radius: 24px

.button
    border: none
    cursor: pointer
    margin: 10px
    transition: .1s linear
    font-family: 'Roboto','sans-serif'
    font-weight: bolder

    &.primary
        background-color: #3367B4
        color: #fff

        &:hover
            background-color: #3a8dd1
            color: #fff

        &:active
            background-color: #007bff

    &.outlined
        color: #3367B4
        border: #C1C2CF solid 0.5px
        margin: 0
        padding: 10px
        font-weight: bolder

        &:hover
            color: #fff

    &.text
        border: none
        color: #3367B4
        font-weight: bolder
        background: none
        margin: 0
        padding: 0


    &.small
        min-width: $button_width * 0.7
        min-height: $button_height * 0.7
        border-radius: $border_radius * 0.7
        padding: scale-padding($button_padding, 0.75)
        font-size: 12px
        font-weight: bolder

    &.basket
        min-width: $button_width * 0.7
        min-height: 35px
        border-radius: $border_radius * 0.7
        padding: scale-padding($button_padding, 0.75)
        font-size: 12px
        font-weight: bolder

    &.medium
        min-width: $button_width
        min-height: $button_height
        padding: $button_padding
        border-radius: $border_radius
        font-size: 14px
        font-weight: bolder


    &.large
        min-width: $button_width * 1.3
        min-height: $button_height * 1.3
        border-radius: $border_radius * 1.3
        padding: scale-padding($button_padding, 1.3)
        font-size: 18px
        font-weight: bolder

    &.secondary
        background-color: #6c757d
        color: #fff
        font-weight: bolder

        &:hover
            background-color: #5a6268

.ripple
    position: absolute
    border-radius: 50%
    background-color: rgba(255, 255, 255, 0.7)
    transform: scale(0)
    animation: ripple-animation 250ms linear

@keyframes ripple-animation
    to
        transform: scale(1)
        opacity: 0