.login_form
    display: -webkit-flex 
    display: flex 
    -webkit-flex-direction: column 
    flex-direction: column 
    -webkit-flex-wrap: nowrap 
    flex-wrap: nowrap 
    -webkit-justify-content: space-around 
    justify-content: space-around 
    -webkit-align-content: space-around 
    align-content: space-around 
    -webkit-align-items: center 
    align-items: center
