.card_image_container
  max-width: 200px
  max-height: 200px
  cursor: pointer !important

  .card_image
    position: absolute
    bottom: -50%,
    right: -50%,
    width: 120% !important
    height: 120%
    border-radius: 50%
    object-fit: cover
