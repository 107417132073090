.header-desktop
  display: flex
  flex-direction: row
  justify-content: space-around
  height: 100px

.header-container
  -webkit-box-shadow: 0px 4px 8px 0px rgba(34, 60, 80, 0.2)
  -moz-box-shadow: 0px 4px 8px 0px rgba(34, 60, 80, 0.2)
  box-shadow: 0px 4px 8px 0px rgba(34, 60, 80, 0.2)
  z-index: 1000


.header-logo
  max-height: 50px
