.card_default
    background-color: #F6F6F8
    border-radius: 15px
    transition: box-shadow 0.3s ease, transform 0.1s ease
    outline: none

.with_padding
    padding: 20px 5px 40px

.active
    cursor: pointer
    outline: none

    &:hover
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1)

    &:active
        transform: scale(0.98)