$color-primary: #3367B4
$color-border-gray: #C1C2CF

@font-face
    font-family: 'Roboto'
    src: url('assets/fonts/roboto/Roboto-Thin.ttf')
    font-weight: 100
    font-style: normal

@font-face
    font-family: 'Roboto'
    src: url('assets/fonts/roboto/Roboto-ThinItalic.ttf')
    font-weight: 100
    font-style: italic

@font-face
    font-family: 'Roboto'
    src: url('assets/fonts/roboto/Roboto-Black.ttf')
    font-weight: 900
    font-style: normal

@font-face
    font-family: 'Roboto'
    src: url('assets/fonts/roboto/Roboto-BlackItalic.ttf')
    font-weight: 900
    font-style: italic

@font-face
    font-family: 'Roboto'
    src: url('assets/fonts/roboto/Roboto-Bold.ttf')
    font-weight: 700
    font-style: normal


@font-face
    font-family: 'Roboto'
    src: url('assets/fonts/roboto/Roboto-BoldItalic.ttf')
    font-weight: 700
    font-style: italic


@font-face
    font-family: 'Roboto'
    src: url('assets/fonts/roboto/Roboto-Italic.ttf')
    font-weight: 400
    font-style: italic


@font-face
    font-family: 'Roboto'
    src: url('assets/fonts/roboto/Roboto-Light.ttf') format('truetype')
    font-weight: 300
    font-style: normal


@font-face
    font-family: 'Roboto'
    src: url('assets/fonts/roboto/Roboto-LightItalic.ttf') format('truetype')
    font-weight: 300
    font-style: italic


@font-face
    font-family: 'Roboto'
    src: url('assets/fonts/roboto/Roboto-Medium.ttf') format('truetype')
    font-weight: 500
    font-style: normal


@font-face
    font-family: 'Roboto'
    src: url('assets/fonts/roboto/Roboto-MediumItalic.ttf') format('truetype')
    font-weight: 500
    font-style: italic


@font-face
    font-family: 'Roboto'
    src: url('assets/fonts/roboto/Roboto-Regular.ttf') format('truetype')
    font-weight: 400
    font-style: normal


body 
    font-family: 'Roboto', -apple-system, 'Roboto', BlinkMacSystemFont, 'Segoe UI', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif
    -webkit-font-smoothing: antialiased 
    -moz-osx-font-smoothing: grayscale 
    background-color: white
    margin: 0

body, *
    scrollbar-width: none

::-webkit-scrollbar
    width: 4px
    height: 4px

::-webkit-scrollbar-thumb
    background-color: #888
    border-radius: 10px

::-webkit-scrollbar-thumb:hover
    background-color: #555

::-webkit-scrollbar-track
    background-color: #f1f1f1
    border-radius: 10px


code
    font-family: 'Roboto', -apple-system, 'Roboto', BlinkMacSystemFont, 'Segoe UI', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif

#root
    height: 100vh

.flex-container 
    display: -webkit-flex 
    display: flex 
    -webkit-flex-direction: column 
    flex-direction: column 
    -webkit-flex-wrap: nowrap 
    flex-wrap: nowrap 
    -webkit-justify-content: space-around 
    justify-content: space-around 
    -webkit-align-content: space-around 
    align-content: space-around 
    -webkit-align-items: center 
    align-items: center

.fullwidth
    width: 100%

@function scale-padding($padding, $scale)
    $padding_top: nth($padding, 1) * $scale
    $padding_right: nth($padding, 2) * $scale
    $padding_bottom: nth($padding, 3) * $scale
    $padding_left: nth($padding, 4) * $scale

    @return $padding_top $padding_right $padding_bottom $padding_left

input,
textarea,
button,
select,
div,
a
    -webkit-tap-highlight-color: transparent

button
    transition: transform 0.1s ease

button:active
    transform: scale(0.98)







