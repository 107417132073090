.mobile-frame
  width: 375px
  height: 667px
  padding: 20px
  border-radius: 40px
  background-color: #000
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5)
  display: flex
  justify-content: center
  align-items: center
  position: relative

  &::before, &::after
    content: ''
    position: absolute
    background-color: #333
    border-radius: 10px

  &::before
    width: 60px
    height: 5px
    top: 10px
    left: 50%
    transform: translateX(-50%)

  &::after
    width: 40px
    height: 5px
    bottom: 20px
    left: 50%
    transform: translateX(-50%)

.screen
  width: 100%
  height: 100%
  background-color: white
  border-radius: 20px
  overflow: hidden
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2)
