.badge-container
  position: relative
  display: inline-block

.badge
  position: absolute
  top: 0px
  right: 0px
  background-color: red
  color: white
  border-radius: 50%
  padding: 0.25em 0.5em
  font-size: 0.75em
  display: flex
  align-items: center
  justify-content: center
  height: 0.75rem
  //transform: translate(50%, -50%)
