.icon_button
    border: none
    background-color: transparent
    cursor: pointer
    padding: 10px
    transition: background-color 0.5s ease
    display: flex
    align-items: center
    justify-content: center
    outline: none

    div svg
        font-size: 24px
        color: #6c757d
        transition: color 0.5s ease
        &:hover
            color: #007bff