.grid-container
  display: flex
  flex-wrap: wrap
  align-items: stretch
  overflow-x: auto
  padding: calc(var(--spacing) / 2)
  max-width: calc(90vw - var(--spacing) * 2)

.grid-item
  box-sizing: border-box
  margin-left: calc(var(--spacing) / 2)
  margin-top: calc(var(--spacing) / 2)
  flex: 1
  width: 100%

.grid-horizontal
  flex-wrap: nowrap

=breakpoint($name, $min-width)
  @media (min-width: $min-width)
    @for $i from 1 through 12
      .grid-#{$name}-#{$i}
        flex: 0 0 calc((100% / 12) * $i - var(--spacing))
        max-width: calc((100% / 12) * $i - var(--spacing))

+breakpoint(xs, 0px)
+breakpoint(sm, 576px)
+breakpoint(md, 960px)
+breakpoint(lg, 1280px)
+breakpoint(xl, 1920px)
