@import "FontColors.sass"

.number-field-container
  background-color: white
  flex-direction: row
  border-radius: 20px
  border: 1px solid $color-border-gray
  transition: border-color 0.3s
  height: 35px

  &:focus-within
    outline: none
    border: 1px solid $color-primary
    box-shadow: 0 0 3px $color-border-gray


  input[type="number"]
    width: 25px
    border: none
    font-family: 'Roboto', 'sans-serif'
    font-size: 14px

    &::placeholder
      font-family: 'Roboto','sans-serif'
      font-weight: 100

    &:focus
        outline: none