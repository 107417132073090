.image
  width: 100%
  height: auto
  max-width: 100%
  border-radius: 0
  object-fit: cover

  &.contain
    object-fit: contain


  &.cover
    object-fit: cover


  &.fill
    object-fit: fill


  &.none
    object-fit: none


  &.scale-down
    object-fit: scale-down


  &.rounded
    border-radius: 10px


  &.max-width-500
    max-width: 500px

  &.max-height
    height: 50px