.skeleton
  background-color: #e0e0e0
  position: relative
  overflow: hidden

  &::before
    content: ''
    position: absolute
    top: 0
    left: -150%
    width: 150%
    height: 100%
    background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.2), transparent)
    animation: loading 1.5s infinite ease-in-out

@keyframes loading
  0%
    left: -150%
  50%
    left: 100%
  100%
    left: 100%

/* Вариант для текста (полоса) */
.skeleton-text
  border-radius: 4px

/* Вариант для круга */
.skeleton-circle
  border-radius: 50%
