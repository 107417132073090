.footer
    //position: fixed
    //bottom: 0
    width: 100%
    height: 60px
    line-height: 60px
    background-color: #f5f5f5
    text-align: center
    font-size: 14px
    color: #000
    z-index: 1000